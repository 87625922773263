import React from 'react';
import axios from 'axios';
import moment from 'moment';
import App from 'next/app';
import Head from 'next/head';
import parse from 'html-react-parser';
import {
    initAqlrc, initLangAqlrc, initPage, jwtManager, logoutUser
} from 'aqlrc';
import getAPIUrl from '../lib/getAPIUrl';
import { Router } from '../routes';
import '../styles/global.css';
import 'lightbox-react/style.css';

export default class AquilaApp extends App {
    static async getInitialProps(bundle) {
        initAqlrc();

        let pageProps = {};
        let cache = null;
        let appurl = null;
        let sitename = null;
        let demo = null;
        let langs = null;
        if (typeof window !== 'undefined') {
            cache = window.localStorage.getItem('cache');
            if (cache) {
                cache = JSON.parse(cache);
            }
        }
        if (!cache || (cache && Date.now() >= cache.date)) {
            const resConf = await axios.post(`${getAPIUrl(bundle.ctx)}v2/config`, {
                PostBody: {structure: {environment: 1}}
            });
            appurl = resConf.data.environment.appUrl;
            sitename = resConf.data.environment.siteName;
            demo = resConf.data.environment.demoMode;
            const resLangs = await axios.post(`${getAPIUrl(bundle.ctx)}v2/languages`, { PostBody: { limit: 99 } }); // Récupération des langues
            langs = resLangs.data.datas;
            if (typeof window !== 'undefined') {
                window.localStorage.setItem('cache', JSON.stringify({
                    appurl, sitename, demo, langs, date : Date.now() + 172800000
                }));
            }
        } else {
            appurl = cache.appurl;
            sitename = cache.sitename;
            demo = cache.demo;
            langs = cache.langs;
        }

        // Affectation de la langue dans le local storage en fonction de l'URL
        const lang = bundle.ctx !== undefined && bundle.ctx.query !== undefined && bundle.ctx.query.lang !== undefined ? bundle.ctx.query.lang : langs.find((l) => l.defaultLanguage === true).code;
        if (typeof window !== 'undefined' && (!window.localStorage.getItem('lang') || window.localStorage.getItem('lang') !== lang)) {
            window.localStorage.setItem('lang', lang);
        }
        moment.locale(lang);
        axios.defaults.headers.common.lang = lang;
        initLangAqlrc(lang);

        const routerLang = lang === langs.find((l) => l.defaultLanguage === true).code ? null : lang; // NE PAS TOUCHER !
        const urlLang = lang === langs.find((l) => l.defaultLanguage === true).code ? '' : `/${lang}`; // NE PAS TOUCHER !

        if (bundle.Component.getInitialProps && bundle) {
            bundle.ctx.nsGlobals = {
                Router, langs, lang, routerLang, urlLang
            }; // Permet d'envoyer les globales dans les getInitialProps de chaque page
            pageProps = await bundle.Component.getInitialProps(bundle.ctx);
        }

        // La props "userRequired" est définie dans chaque page à true ou false
        // Elle permet de restreindre les pages qui nécessitent un utilisateur connecté
        // En fonction de ça on redirige ou non vers la page d'accueil
        let user = jwtManager.getUser(bundle.ctx);
        if (pageProps.userRequired) {
            if (user) {
                try {
                    const resUser = await axios.post(`${getAPIUrl(bundle.ctx)}v2/user/${user._id}`, {
                        PostBody : {
                            structure : {
                                isActiveAccount   : 1,
                                company           : 1,
                                civility          : 1,
                                preferredLanguage : 1,
                                type              : 1,
                                addresses         : 1,
                                delivery_address  : 1,
                                billing_address   : 1
                            }
                        }
                    });
                    user = resUser.data;
                } catch (e) {
                    console.error(e);
                    user = undefined;
                }
            }
            if (!user || !Object.keys(user).length) {
                user = undefined;

                // Déconnexion
                await logoutUser(bundle.ctx);

                if (bundle.ctx.req) {
                    return bundle.ctx.res.redirect(`${urlLang}${pageProps.userRequired.url ? pageProps.userRequired.url : ''}`);
                }
                return Router.pushRoute(pageProps.userRequired.route ? pageProps.userRequired.route : 'home', { lang: routerLang });
            }
        }

        // Récupération des données des blocs CMS header / footer + breadcrumb
        const header = pageProps.layoutCms ? pageProps.layoutCms.header : undefined;
        const footer = pageProps.layoutCms ? pageProps.layoutCms.footer : undefined;
        const initData = await initPage(bundle.ctx, lang, header, footer);

        // Bloc CMS du header et de la barre de cookie
        const cmsHead = await axios.post(`${getAPIUrl(bundle.ctx)}v2/component/ns-cms/head`, { lang });
        const cmsCookieBanner = await axios.post(`${getAPIUrl(bundle.ctx)}v2/component/ns-cms/cookie-banner`, { lang });

        pageProps = {
            ...pageProps,
            ...initData,
            demo,
            cmsHead       : cmsHead.data.content,
            messageCookie : cmsCookieBanner.data.content,
            appurl,
            sitename,
            currentUrl    : bundle.ctx.asPath, // => NSMenu
            user,
            gNext         : { Router },
            langs,
            lang,
            routerLang,
            urlLang
        };
        return { pageProps };
    }

    constructor(props) {
        super(props);
        this.state = {};
    }

    /* static async getDerivedStateFromProps() {
        const jwt = jwtManager.get();
        if (jwt) {
            axios.defaults.headers.common.Authorization = jwt;
        } else {
            delete axios.defaults.headers.common.Authorization;
        }
    } */

    componentDidMount = () => {
        const { pageProps } = this.props;
        axios.defaults.headers.common.lang = pageProps.lang;
        initLangAqlrc(pageProps.lang);

        /* Évènements levés pour Google Analytics */
        const init = new CustomEvent('init', {});
        window.dispatchEvent(init);
        let logPageView = new CustomEvent('logPageView', { detail: { url: window.location.pathname } });
        window.dispatchEvent(logPageView);

        Router.onRouteChangeStart = () => {
            const routeChange = new CustomEvent('routeChange', {});
            window.dispatchEvent(routeChange);
        };

        Router.router.events.on('routeChangeComplete', (url) => {
            logPageView = new CustomEvent('logPageView', { detail: { url } });
            window.dispatchEvent(logPageView);
        });

        // Affectation de la langue dans le local storage en fonction de l'URL
        if (!window.localStorage.getItem('lang') || window.localStorage.getItem('lang') !== pageProps.lang) {
            window.localStorage.setItem('lang', pageProps.lang);
        }
    }

    render() {
        const { Component, pageProps } = this.props;
        return (
            <>
                <Head>
                    { pageProps.cmsHead ? parse(pageProps.cmsHead) : null }
                    <meta property="og:site_name" content={pageProps.sitename} />
                    <meta itemProp="name" content={pageProps.sitename} />
                    {
                        !pageProps.demo ? <meta name="robots" content="index,follow" />
                            : (
                                <>
                                    <meta name="robots" content="noindex,nofollow,noarchive" />
                                    <style>{`
                                        body::before { 
                                            content: "/!\\\\ This is a demo mode ! /!\\\\";
                                            background-color: red;
                                            color: #000;
                                            padding: 2px;
                                            width: 100%;
                                            text-align: center;
                                            position: fixed;
                                            z-index: 999;
                                            font-size: 11px;
                                        }
                                    `}</style>
                                </>
                            )
                    }
                </Head>
                <Component {...pageProps} />
            </>
        );
    }
}
